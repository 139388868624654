import React from 'react';
import PropTypes from 'prop-types';
import IncentiveCard from '../IncentiveCard/IncentiveCard';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import sortIncentivesCatalog from '../../functions/incentives/Sort/sortIncentivesCatalog';

import './IncentiveCatalog.scss'

import { useWidget } from '../../context/widgetContext';
const IncentiveCatalog = ({ incentives }) => {
  const { userPrefs } = useWidget();
  if (!incentives) return <LoadingSpinner />;

  const sortedIncentives = sortIncentivesCatalog(
    incentives,
    userPrefs.get('incentiveSortType'),
    userPrefs.get('incentiveSortDirection')
  );

  return (
    <div className="homepage-incentives-list">
      {sortedIncentives.map((incentive, index) => (
        <IncentiveCard
          incentive={incentive}
          key={index}
        />
      ))}
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  includeTotal: PropTypes.bool,
};
