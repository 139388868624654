import React from 'react'
import TextField from "./TextField"
import { useDispatch, useSelector } from 'react-redux';
import { updateZipcode } from '../../redux/Slices/userSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import ToolTip from '../shared/ToolTip/ToolTip';
import IconZip from "../../client_customizations/assets/images/icons/icon-zip.svg";
import { getZipcode } from '../../redux/selector';


const TextFieldZipcode = () => {
    const intl = useIntl();
    const zipcode = useSelector(getZipcode)
       
    const dispatch = useDispatch()
    const changeZipcode = (zipcode) => {
        dispatch(updateZipcode(zipcode))
    }
    const tooltip = intl.formatMessage({
            id: 'zipcodeTooltip',
            defaultMessage:
              'Used to find potential incentives, estimate fuel prices, and calculate emissions for your area.',
       })
    
    return (
       <>
              <div className="incentive-label-select">
                     <label for='zipcodefield'>
                            <FormattedMessage
                            id="yourLocation"
                            defaultMessage="Your Location"
                            description="yourLocation"
                            />
                            {tooltip && <ToolTip message={tooltip} id={'zipcode_tooltip'} /> }
                     </label>
              </div>
              <div className="zip-code-input-group">
                     <div className="input-group-prepend">
                            <div className="input-group-text">
                                   <img src={IconZip} alt="" style={{ width: 15 }} />
                            </div>
                     </div>
                     <TextField
                            id='zipcodefield'
                            defaultValue={zipcode}
                            onChange={changeZipcode}
                            maxLength={5}
                            placement="bottom"
                     />
              </div>
              <hr/>
       </>
    )
}

export default TextFieldZipcode;
