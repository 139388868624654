import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { FormatAsDollars } from './../../utils/Helpers/Format';
import { useIntl } from 'react-intl';

const CostToOwnComparisonChartMobile = ({
  carNames,
  carsCosts,
  displayEvCostBreakdownOption,
  displayEvCostBreakdown,
}) => {
  let chartData = {};

  const intl = useIntl();

  chartData = {
    labels: [[carNames[0][0]], [carNames[1][0]]],
    datasets: [
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.vehicle',
          defaultMessage: 'Vehicle after incentives',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_NET}`,
        data: carsCosts.map((carCost) => {
          return carCost.vehicle.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.taxesFeers',
          defaultMessage: 'Taxes and Fees',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_TAXESFEES}`,
        data: carsCosts.map((carCost) => {
          return carCost.taxesFees.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.charger',
          defaultMessage: 'Charger',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_CHARGER}`,
        data: carsCosts.map((carCost) => {
          return carCost.charger.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.electricity',
          defaultMessage: 'Electricity',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_ELECTRICITY}`,
        data: carsCosts.map((carCost) => {
          return carCost.electricity.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.gasoline',
          defaultMessage: 'Gasoline',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_GASOLINE}`,
        data: carsCosts.map((carCost) => {
          return carCost.gasoline.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.maintenance',
          defaultMessage: 'Maintenance',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_MAINTENANCE}`,
        data: carsCosts.map((carCost) => {
          return carCost.maintenance.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.insurance',
          defaultMessage: 'Insurance',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_INSURANCE}`,
        data: carsCosts.map((carCost) => {
          return carCost.insurance.total;
        }),
        abbreviatedLabel: '',
      },
      {
        showLine: false,
        legend: false,
        pointRadius: 0,
        pointHoverRadius: 0,
        datalabels: {
          align: 'right',
          color: '#2A2A2A',
        },
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    tooltips: {
      yAlign: 'center',
      callbacks: {
        label: function (tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          value = FormatAsDollars(value);
          return ' ' + label + ': ' + value;
        },
      },
    },
//     scales: {
//       xAxes: [
//         {
//           stacked: true,
//           ticks: {
//             autoSkip: false,
//             maxRotation: 45,
//             minRotation: 45,
//           },
//         },
//       ],
//       yAxes: [
//         {
//           stacked: true,
//           ticks: {
//             font: {
//               weight: 700,
//               color: '#2A2A2A',
//             },
//           },
//         },
//       ],
//     },
    legend: {
      position: 'bottom',
      onClick: function (e) {
        e.stopPropagation();
      },
      display: true,
      labels: {
        usePointStyle: true,
        boxWidth: 12,
        padding: 20,
        filter: function (item) {
          return !!item?.text?.trim();
        },
      },
    },
    layout: {
      padding: {
        right: 25,
        top: 25,
      },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const datasetArray = [];
          ctx.chart.data.datasets.forEach((dataset) => {
            if (dataset.data[ctx.dataIndex] !== undefined) {
              datasetArray.push(dataset.data[ctx.dataIndex]);
            }
          });
          function totalSum(total, datapoint) {
            return total + datapoint;
          }
          let sum = datasetArray.reduceRight(totalSum, 0);
          if (ctx.datasetIndex === datasetArray.length - 1) {
            return FormatAsDollars(sum);
          } else {
            return '';
          }
        },

        color: '#000',
        align: 'top',
        font: {
          weight: 500,
        },
        anchor: 'top',
      },
      legend: {
        labels: {
          font: {
            size: 14,
            weight: 400,
            textAlign: 'center',
            color: '#2A2A2A',
          },
        },
      },
    },
  };

  return (
    <Bar data={chartData} height={512} type="verticalBar" options={options} />
  );
};

export default CostToOwnComparisonChartMobile;

CostToOwnComparisonChartMobile.propTypes = {
  carNames: PropTypes.array,
  carCosts: PropTypes.array,
  displayEvCostBreakdownOption: PropTypes.bool,
  displayEvCostBreakdown: PropTypes.bool,
  displayFuelOnly: PropTypes.bool,
};
