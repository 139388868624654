import React, { useEffect } from "react";

import Range from "../../../components/shared/InputElements/Range";
import ToolTip from '../../../components/shared/ToolTip/ToolTip';
import { getActiveVehicle, getPercentElectric } from "../../../redux/selector"
import { useDispatch, useSelector } from 'react-redux';
import { updatePercentElectric } from "../../../redux/Slices/userSlice";
import { FormatAsPercentRounded } from "../../../utils/Helpers/Format";

const descriptionFn = val => {
       return FormatAsPercentRounded(val);
};

const SlidePercentElectric = ({
       description = descriptionFn
}) => {
    const dispatch = useDispatch()
    const percentElectric = useSelector(getPercentElectric)
    const isBz4x = useSelector(getActiveVehicle) === "bZ4X"
    const isPrius = useSelector(getActiveVehicle) === 'Prius Prime'
    const isRav4 = useSelector(getActiveVehicle) === 'RAV4 Prime'

    const maxNumber = 100;
    
    const updateElectricPercentage = (percent) => {
        if (!isNaN(Number(percent))) {
            if (percent > maxNumber) {
                dispatch(updatePercentElectric(maxNumber))
            } else {
                dispatch(updatePercentElectric(Number(percent)))
            }
        }
    }

    useEffect(() => {
        if(isBz4x) {
            updateElectricPercentage(100)
        }
        if(isPrius || isRav4) {
            updateElectricPercentage(70)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isBz4x, isPrius, isRav4])

    return (
       <div className="input-group-container">
              <div className="incentive-label-select">
                     <label htmlFor="annualMileage">Electric Miles Driven</label>
                     <ToolTip message={"Estimate what percentage of your driving miles will be under pure electric power."} id={"slide-percent-electric-tooltip"} />
              </div>
              <Range
                     value={percentElectric}
                     description={description}
                     ariaLabel="Annual Mileage"
                     rangeMin={0}
                     rangeMax={100}
                     rangeStep={10}
                     handler={e => updateElectricPercentage(e.target.value)}
                     disabled={isBz4x}
              />
       </div>
    )
}

export default SlidePercentElectric
