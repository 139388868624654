import React from 'react';
import { useWidget } from '../../context/widgetContext';

const Tabs = () => {
  const { enabledModules, activeModuleId, setActiveModule } = useWidget();

  const handleClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveModule(id)
  }

  return (
    <div className="tabs">
      {enabledModules.map(({ id, label }) => (
        <button
          key={id}
          style={{
            cursor: 'pointer',
            borderBottom: id === activeModuleId ? '2px solid black' : '2px solid #D8D8D8',
            fontWeight: id === activeModuleId ? '600' : 'normal',
            color: 'black'
          }}
          onClick={e => handleClick(e, id)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default Tabs