import React from 'react'
import DoughnutGraph from "../../components/Graphs/DoughnutGraph/DoughnutGraph"
import { useSelector } from 'react-redux';

import SelectVehicleModelFilter from '../../client_customizations/components/InputComponents/SelectVehicleModelFilter/SelectVehicleModelFilter';
import SelectVehicleTrimFilter from '../../client_customizations/components/InputComponents/SelectVehicleTrimFilter/SelectVehicleTrimFilter';
import SelectAnnualMileage from "../../components/Filters/Select/SelectAnnualMileage"
import SlideEquivalentGasMPG from "../../components/Filters/Slider/SlideEquivalentGasMPG";
import SlideElectricityPrice from "../../components/Filters/Slider/SlideElectricityPrice";
import SlideFuelPrice from "../../components/Filters/Slider/SlideFuelPrice";
import SlidePercentElectric from "../../components/Filters/Slider/SlidePercentElectric";

import TabStructure from '../../components/TabStructure/TabStructure';
import TextFieldZipcode from "../../components/TextField/TextFieldZipcode"
import FuelCost from "../../functions/vehicle/CostOfOwnership/updatedCalcTotalCOW";
import { incentiveSavingsOfGivenTypes } from "../../functions/incentives/incentiveHelperFunctions";
import { FormatAsDollars } from "../../utils/Helpers/Format";
import './CostOfOwnership.scss';
import { useWidget } from '../../context/widgetContext.js';

import { FormattedMessage } from 'react-intl';

const CostOfOwnership = ({
    vehicle,
    activeTrim,
}) => {
       const { userPrefs, electricVehicles } = useWidget();
    const { annualMileage, equivalentGasMPG, electricityPrice, fuelPrice, percentElectric, numYears } = useSelector(state => state.user)

    const fuelSavingsData = FuelCost.totalCost(annualMileage,equivalentGasMPG, vehicle, electricityPrice, fuelPrice, percentElectric) * numYears;

    let federalStateUtilityIncentiveSavings = incentiveSavingsOfGivenTypes(vehicle.incentives, ["Country", "state", "power supplier"])

    // Array of Incentive, Fuel Savings, Maintenance
    const data = [federalStateUtilityIncentiveSavings, fuelSavingsData]
    const labels = ["Potential incentives", "Potential fuel savings", "Potential Maintenance Savings", "Potential Insurance Savings"];
    const backgroundColor = ["#890713", "#E10A1D", "#D8D8D8", "#767676"]

    const inputs = [
        <TextFieldZipcode />,
        <SelectVehicleModelFilter
              vehicles={electricVehicles}
              makeOption={userPrefs.get('vehicleMakeFilter')} />,
        <SelectVehicleTrimFilter
              vehicles={electricVehicles}
              makeOption={userPrefs.get('vehicleMakeFilter')}
              modelOption={userPrefs.get('vehicleModelFilter')}
        />,
        <hr/>,
        <SelectAnnualMileage />,
        <SlidePercentElectric />,
        <SlideFuelPrice />,
        <SlideEquivalentGasMPG type={vehicle.fuel} />,
        <SlideElectricityPrice />,
    ]

    const renderInputs = (inputs) => {
        return inputs.map(input => {
              return (
                     <>
                            {input}
                     </>
              )
        })
    }

    const totalSavings = FormatAsDollars(data.reduce((acc, num) => acc + num, 0))    

    const left = (
       <div className='filters-container'>
              <h2>Filters</h2>
              {renderInputs(inputs)}
              <button className="incentives-submit-button">
                     <FormattedMessage
                     id="incentives.applyButton"
                     defaultMessage="Apply Filters"
                     description="Apply Filters"
                     />
              </button>
              <div className="incentives-disclaimer">
                     <FormattedMessage
                     id="incentives.disclaimer"
                     defaultMessage="Exact eligibility may vary based on numerous factors. Please consult the incentive grantor and/or your tax professional for the most accurate information."
                     description="Incentive Discalimer"
                     />
              </div>
       </div>
    );

    const middle = (
       <div className="cost-of-ownership-container">
              <h2>Over 5 years, you could save as much as <strong>{totalSavings}</strong>.</h2>
              <h3>The more you drive, the more you could potentially save.</h3>
              <DoughnutGraph
                     graphData={data}
                     labels={labels}
                     backgroundColor={backgroundColor}
                     vehicle={vehicle}
                     activeTrim={activeTrim}
              />
       </div>
    );

    return (
        <TabStructure
            left={left}
            middle={middle}
        />
    )
}

export default CostOfOwnership
