import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {
  FormatAsDollars,
} from './../../../utils/Helpers/Format';
import { useIntl } from 'react-intl';

const CostToOwnComparisonChart = ({
  carNames,
  carsCosts,
}) => {
  let chartData = {};

  const intl = useIntl();

  chartData = {
    labels: carNames,
    datasets: [
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.vehicle',
          defaultMessage: 'Vehicle after incentives',
        }),
        backgroundColor: `#E10A1D`,
        data: carsCosts.map((carCost) => {
          return carCost.vehicle.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.charger',
          defaultMessage: 'Charger',
        }),
        backgroundColor: `#B00716`,
        data: carsCosts.map((carCost) => {
          return carCost.charger.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.electricity',
          defaultMessage: 'Electricity',
        }),
        backgroundColor: `#890713`,
        data: carsCosts.map((carCost) => {
          return carCost.electricity.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.gasoline',
          defaultMessage: 'Gasoline',
        }),
        backgroundColor: `#767676;`,
        data: carsCosts.map((carCost) => {
          return carCost.gasoline.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.maintenance',
          defaultMessage: 'Maintenance',
        }),
        backgroundColor: `#58595B`,
        data: carsCosts.map((carCost) => {
          return carCost.maintenance.total;
        }),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.costOfOwnership.chart.insurance',
          defaultMessage: 'Insurance',
        }),
        backgroundColor: `#000000`,
        data: carsCosts.map((carCost) => {
          return carCost.insurance.total;
        }),
        abbreviatedLabel: '',
      },
    ],
  };

  const options = {
       indexAxis: 'y',
       maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          value = FormatAsDollars(value);
          return ' ' + label + ': ' + value;
        },
        title: function (tooltipItems, data) {
          let total = tooltipItems.reduce(function (acc, item) {
            return acc + item.xLabel;
          }, 0);
          return tooltipItems[0].yLabel + ': ' + FormatAsDollars(total);
        },
      },
    },
    scales: {
       xAxis:{
           stacked: true,
           ticks: {
             callback: function(value) {
               value = FormatAsDollars(value, intl.locale);
               return value;
             },
             beginAtZero: true
           }
         },
       yAxis:
         {
           stacked: true,
           ticks: {
             font: {
               weight: 700
             }
           }
         }
     },
    layout: {
      padding: {
        right: 25,
        top: 25
      },
    },
    plugins: {
       legend: {
              position: 'bottom',
              display: true,
              labels: {
                     boxWidth: 12,
                     boxHeight: 12,
                     usePointStyle: true,
                     pointStyle: 'circle',
                     padding: 20,
              }
            },
       datalabels: {
         formatter: (value, ctx) => {
           let datasets = ctx.chart.data.datasets;
           if (ctx.datasetIndex === datasets.length - 1) {
             let sum = 0;
             datasets.forEach(dataset => {
               sum += dataset.data[ctx.dataIndex];
             });
             return FormatAsDollars(sum, intl.locale);
           } else {
             return "";
           }
         },
         align: "end",
         anchor: "end",
         color: "#333333",
         font: {
           weight: 700
         }
       }
     }
  };

  return (
    <Bar
      data={chartData}
      height={212}
      options={options}
    />
  );
};

export default CostToOwnComparisonChart;

CostToOwnComparisonChart.propTypes = {
  carNames: PropTypes.array,
  carCosts: PropTypes.array,
  displayEvCostBreakdownOption: PropTypes.bool,
  displayEvCostBreakdown: PropTypes.bool,
  displayFuelOnly: PropTypes.bool,
};