import { useState, useEffect, useRef } from 'react';

function useElementSize() {
    const ref = useRef(null);

    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const element = ref.current;

        const resizeObserver = new ResizeObserver(entries => {
            if (!entries || !entries.length) return;
            const { width, height } = entries[0].contentRect;
            setSize({ width, height });
        });

        if (element) {
            resizeObserver.observe(element);
        }

        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, []);

    return [ref, size];
}

export default useElementSize;