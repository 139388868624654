import React from "react";
import PropTypes from "prop-types";
import ZipcodeErrorState from "./ZipcodeErrorState";
import IconZip from "../../../client_customizations/assets/images/icons/icon-zip.svg";
import { ValidateFiveDigitCode } from "../../../utils/Helpers/USPostalCodeValidator";
import { FormattedMessage } from 'react-intl';
import ToolTip from "../../shared/ToolTip/ToolTip";


const ControlledInputZipcode = ({
  zipcode,
  setZipcode,
  handleSubmit,
  workingZipcode,
  id,
  isUpdating,
  isNotFound,
  showButtonBelow,
  showButtonInline,
  hideValidateRequest, 
  tooltip,
  userLocation
}) => {
  const zipcodeIsValid = ValidateFiveDigitCode(workingZipcode);
  const zipcodeIsSynced = workingZipcode === zipcode;
  const isInvalid =
    !isUpdating && (!zipcodeIsValid || (zipcodeIsSynced && isNotFound));
  const idAria = id + " help";
  const showButton = showButtonBelow || showButtonInline;
  const isAPSzipcode = userLocation?.power_supplier?.some(supplier => supplier.name === 'APS') ?? true;

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const inputClassName = isUpdating
    ? "form-control"
    : `form-control${isInvalid ? " is-invalid" : ""}${zipcodeIsSynced && !isNotFound && isAPSzipcode ? " is-valid" : ""
    }`;

  const renderButton = (
    <button
      type="button"
      className="btn btn-default mb-2"
      onClick={handleSubmit}
      disabled={isUpdating}
      id="update-zipcode-button"
    >
      Update
    </button>
  );

  const renderError = (
    <ZipcodeErrorState
      zipcode={zipcode}
      workingZipcode={workingZipcode}
      isValid={zipcodeIsValid}
      isSynced={zipcodeIsSynced}
      isUpdating={isUpdating}
      isNotFound={isNotFound}
      hideValidateRequest={hideValidateRequest}
    />
  );

  return (
    <>
      <div className={showButtonInline ? "form-inline" : "incentive-label-select"}>
        {!showButtonInline && <label htmlFor={id}>
          <FormattedMessage
            id="yourLocation"
            defaultMessage="Your Location"
            description="yourLocation"
          />{tooltip && <ToolTip message={tooltip} id={'zipcode_tooltip'} /> }
        </label>}
        </div>
        <div
          className={`zip-code-input-group mb-2${showButtonInline ? " mr-sm-2" : ""}`}
        >
          <div className="input-group-prepend">
            <div className="input-group-text">
              <img src={IconZip} alt="" style={{ width: 15 }} />
            </div>
          </div>
          <input
            id={id}
            className={inputClassName}
            aria-describedby={idAria}
            value={workingZipcode}
            disabled={isUpdating}
            onChange={setZipcode}
            onKeyDown={e => handleKeyDown(e)}
          />
          {showButton && renderButton}
        </div>
      {renderError}
    </>
  );
};

export default ControlledInputZipcode;

ControlledInputZipcode.propTypes = {
  zipcode: PropTypes.string,
  setZipcode: PropTypes.func,
  handleSubmit: PropTypes.func,
  workingZipcode: PropTypes.string,
  id: PropTypes.string,
  isUpdating: PropTypes.bool,
  isNotFound: PropTypes.bool,
  showButtonBelow: PropTypes.bool,
  showButtonInline: PropTypes.bool,
  hideValidateRequest: PropTypes.bool
};