import React from "react";
import Range from "../../../components/shared/InputElements/Range";
import ToolTip from '../../../components/shared/ToolTip/ToolTip';
import { getFuelPrice } from "../../../redux/selector"
import { useDispatch, useSelector } from 'react-redux';
import { updateFuelPrice } from "../../../redux/Slices/userSlice";
import { FormatAsCents } from "../../../utils/Helpers/Format";

const descriptionFn = val => {
       return FormatAsCents(val) + " / gallon";
};

const SlideFuelPrice = ({
       description = descriptionFn
}) => {
    const dispatch = useDispatch()
    const fuelPrice = useSelector(getFuelPrice)
    const maxNumber = 10

    const updateFuelCost = (price) => {
        if (!isNaN(Number(price))) {
            if (price > maxNumber) {
                dispatch(updateFuelPrice(maxNumber))
            } else {
                dispatch(updateFuelPrice(price))
            }
        }
    }

    const onBlurUpdateFuelCost = (price) => {
        if (!isNaN(Number(price))) {
            if (price > maxNumber) {
                dispatch(updateFuelPrice(maxNumber))
            } else {
            dispatch(updateFuelPrice(Number(price).toFixed(2)))
            }
        }
    }

    return (
        <div className="input-group-container">
              <div className="incentive-label-select">
                     <label htmlFor="range-gas-price">Gasoline Price</label>
                     <ToolTip message={"Enter your local gas price. Our default is based on your region's most recent gas price, updated monthly."} id={"slide-price-gasoline-tooltip"} />
              </div>
              <Range
                     id="range-gas-price"
                     value={fuelPrice}
                     description={description}
                     ariaLabel="Annual Mileage"
                     rangeMin={2}
                     rangeMax={maxNumber}
                     rangeStep={0.5}
                     handler={e => updateFuelCost(e.target.value)}
                     onBlur={onBlurUpdateFuelCost}
              />
       </div>
    )
}

export default SlideFuelPrice
