/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';

import fetchVehicleIncentivesWithHandle from '../services/fetchVehicleIncentivesWithHandle';
import fetchIncentives from '../services/fetchIncentives';


const useIncentivesData = (params, language) => {
  const [incentives, setIncentives] = useState(null);
  const { postcode, income, size, taxStatus, tradeIn, handle} = params
  
  useEffect(() => {
    const fetchIncentivesData = async () => {
      const params = {
        postcode: postcode,
        vehicle_handle: handle,
        household_size: size,
        household_income: income,
        turn_in_clunker: tradeIn,
        tax_filing_type: taxStatus,
        lang: language === 'EN' ? null : language,
      };
      
      try {
        const hasVehicle = params?.vehicle_handle !== '' && params?.vehicle_handle?.length 
        const data = hasVehicle
          ? await fetchVehicleIncentivesWithHandle(params)
          : await fetchIncentives(params);
        if (data) {
          setIncentives(data);
        }
      } catch (error) {
        console.error('Error loading incentives data:', error);
      }
    };

    fetchIncentivesData();

  }, [params, language]);

  return incentives;
};

export default useIncentivesData;