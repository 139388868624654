/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, createContext, useEffect, useMemo } from 'react';
import moduleConfig from './moduleConfig';
import { loadState } from '../utils/LocalStorage/LocalStorage';
import loadUserPrefs from './UserPrefs/loadUserPrefs';
import useZipcodeData from '../hooks/useZipcodeData';
import getUserPref from './UserPrefs/getUserPref';
import useIncentivesData from '../hooks/useIncentivesData';
import useElectricVehicleData from '../hooks/useElectricVehicleData';
import USER_PREF_PRESETS from './UserPrefs/USER_PREF_PRESETS';
import { useSelector } from 'react-redux';

const WidgetContext = createContext(null);


const useWidget = () => {
  const context = useContext(WidgetContext);
  if (!context) {
    throw new Error('useWidget deve ser usado dentro de um WidgetProvider');
  }
  return context;
};


const WidgetProvider = ({ children }) => {
  const [activeModuleId, setActiveModuleId] = useState(null);
  const [zipcode, setZipcode] = useState('');
  const [incentivePrefsModalIsOpen, setIncentivePrefsModalIsOpen] = useState(false);
  const user = useSelector((state) => state.user)
  
  // MODULE / TABS
  const enabledModules = Object.values(moduleConfig).filter(module => module.moduleEnabled);

  useEffect(() => {
    const firstEnabledModule = enabledModules.find(module => module.moduleEnabled);
    if (firstEnabledModule  && activeModuleId === null) {
      setActiveModuleId(firstEnabledModule.id);
    }
  }, [enabledModules]);

  const setActiveModule = (id) => {
    if (enabledModules.some(module => module.id === id)) {
      setActiveModuleId(id);
    }
  };

  //UserPrefs
  const existingState = loadState() || {};
  const savedPrefs = useMemo(() => {
    const prefs = existingState?.userPreferences || {};
    const defaultFilters = { vehicleFormFactorFilters: {}, vehicleFuelTypeFilters: {}, chargerTypeFilters: {}, chargerFormFactorFilters: {}, chargerWifiFilter: {}};
    return { ...defaultFilters, ...prefs };
  }, []); 
  const [userPreferences, setUserPreferences] = useState(loadUserPrefs(savedPrefs));
  const { userLocation, userLocationNotFound, zipcodeUpdating, setZipcodeUpdating } = useZipcodeData(user.zipcode);

  const incentivesParams = useMemo(() => ({
    postcode: user.zipcode,
    provider: user.electricityProvider,
    income: user.householdIncome,
    size: user.householdSize,
    taxStatus: user.taxFilingStatus,
    tradeIn: user.tradeInVehicle,
    handle: user.vehicles,
  }),[user.zipcode, user.electricityProvider, user.householdIncome, user.householdSize, user.taxFilingStatus, user.tradeInVehicle, user.vehicles])
  
  
  const incentives = useIncentivesData(incentivesParams, 'EN')

  const { electricVehicles, usedElectricVehicles } = useElectricVehicleData(incentivesParams, 'EN')

  const updateUserPreferences = (newPrefs) => {
    const prefs = { ...userPreferences, ...newPrefs };
    setUserPreferences(prefs); 

    if (newPrefs.zipcode && userPreferences.zipcode !== newPrefs.zipcode) {
      setZipcodeUpdating(true);
    }
  };

  const incentivePrefsModalToggle = (override) => {
    const newValue = typeof override === 'boolean' ? override : !incentivePrefsModalIsOpen;
    setIncentivePrefsModalIsOpen(newValue);
  };

  const userPrefs = {
    get: (key) => getUserPref(key, userPreferences),
    getPreset: (key) => USER_PREF_PRESETS[key], 
    set: updateUserPreferences,
    zipcodeIsNotFound: userLocationNotFound,
    zipcodeIsUpdating: zipcodeUpdating,
    showIncentivePrefsModal: incentivePrefsModalIsOpen,
    toggleIncentivePrefsModal: incentivePrefsModalToggle,
    syncWorkingZipcode: () => updateUserPreferences({ zipcode: getUserPref('workingZipcode') }, userPreferences), 
  };

  const value = {
    enabledModules,
    activeModuleId,
    setActiveModule,
    zipcode,
    setZipcode,
    existingState,
    userLocation,
    incentives,
    electricVehicles,
    usedElectricVehicles,
    userPrefs,
    userPreferences
  };

  return (
    <WidgetContext.Provider value={value}>
      {children}
    </WidgetContext.Provider>
  );
};

export { useWidget, WidgetProvider };
