import React from "react";
import PropTypes from "prop-types";

import { FormatAsThousands } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { useIntl } from 'react-intl';
import { useWidget } from "../../../context/widgetContext";

const SlideMilesDrivenAnnually = ({
  id = "miles-driven-annually-range",
  ...rest
}) => {
  const intl = useIntl()
  const { userPrefs } = useWidget()
  const description = val => {
    return `${FormatAsThousands(val)} ${intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"})}`;
  };

  return (
    <Range
      id={id}
      value={userPrefs.get("milesDrivenAnnually")}
      rangeMin={2000}
      rangeMax={50000}
      rangeStep={2000}
      description={description}
      handler={e => userPrefs.set({ milesDrivenAnnually: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMilesDrivenAnnually;

SlideMilesDrivenAnnually.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
