import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import SelectVehicleModelFilter from '../../client_customizations/components/InputComponents/SelectVehicleModelFilter/SelectVehicleModelFilter';
import SelectVehicleTrimFilter from '../../client_customizations/components/InputComponents/SelectVehicleTrimFilter/SelectVehicleTrimFilter';
import TabStructure from '../../components/TabStructure/TabStructure';
import { useGetLocationQuery } from "../../redux/services/unifiedAPIQuery"
import BarGraph from "../../components/Graphs/BarGraph/BarGraph"
import calcEmissions from '../../functions/vehicle/Emissions/calcEmissions';
import { LBC02_PER_GALLON_OF_GAS } from "../../data/assumptions/ASSUMPTIONS"
import SelectAnnualMileage from "../../components/Filters/Select/SelectAnnualMileage"
import SlideEquivalentGasMPG from "../../components/Filters/Slider/SlideEquivalentGasMPG";
import SlidePercentElectric from "../../components/Filters/Slider/SlidePercentElectric";
import { updateElectricityPrice, updateFuelPrice } from '../../redux/Slices/userSlice';
import { FormatAsTwoDecimals, FormatAsPercent, FormatAsThousands } from '../../utils/Helpers/Format'
import TextFieldZipcode from "../../components/TextField/TextFieldZipcode"
import { useWidget } from '../../context/widgetContext.js';
import queryString from 'query-string';

import './Emissions.scss';
import { FormattedMessage } from 'react-intl';


const Emissions = () => {
       const { annualMileage, percentElectric, numYears, vehicles, zipcode, equivalentGasMPG } = useSelector(state => state.user)
       const { userPrefs, electricVehicles } = useWidget();
       const dispatch = useDispatch()
       const { data: locationData } = useGetLocationQuery({
              postcode: zipcode
       })

       useEffect(() => {
              if (locationData) {
                const electricityPrice = FormatAsTwoDecimals(locationData.location.regional_fuel_cost[0].electricity / 100)
                const fuelPrice = FormatAsTwoDecimals(locationData.location.regional_fuel_cost[0].gasoline)
                dispatch(updateElectricityPrice(electricityPrice))
                dispatch(updateFuelPrice(fuelPrice))
              }
       }, [locationData, dispatch])


    const fullVehicle = useMemo(() => {
       return electricVehicles.filter(e => e.handle === vehicles)[0]
    }, [vehicles, electricVehicles])
    const gasolineEfficiency = equivalentGasMPG;
    const gasEmission = calcEmissions.gasEmitted(annualMileage, gasolineEfficiency, LBC02_PER_GALLON_OF_GAS.value) * numYears
    const query = queryString.parse(window.location.search);

    let evEmission = 0;
    if(locationData && fullVehicle) {

       evEmission = calcEmissions.evEmitted(
              annualMileage, 
              (fullVehicle.electric_efficiency ?? 0), 
              locationData.location.regional_electricity[0].emissions.emissions_co2, 
              (fullVehicle.fossil_fuel_efficiency ?? 0), 
              LBC02_PER_GALLON_OF_GAS.value, 
              fullVehicle, 
              percentElectric) 
       * numYears
    }

    const graphData = [gasEmission, evEmission]
    const labels = [['Gas-only', 'vehicle'], `${fullVehicle?.model_year ?? ''} ${fullVehicle?.model ?? ''}`]
    const backgroundColor = ['#D8D8D8', '#E10A1D']

    let inputs = [
        <TextFieldZipcode />,
        <SelectVehicleModelFilter
              vehicles={electricVehicles}
              makeOption={userPrefs.get('vehicleMakeFilter')}
              key='vehicleModelFilter' 
       />,
       <SelectVehicleTrimFilter
              vehicles={electricVehicles}
              makeOption={userPrefs.get('vehicleMakeFilter')}
              modelOption={userPrefs.get('vehicleModelFilter')}
       />,
        <hr/>,
        <SelectAnnualMileage />,
        <SlidePercentElectric key='milesDriven' />,
        <SlideEquivalentGasMPG type={fullVehicle?.fuel ?? 'gas'} />,
        <div className="incentives-disclaimer">
              <FormattedMessage
                     id="emission.disclaimer"
                     defaultMessage="This is an estimate to be used for comparison purposes only. Actual results will vary depending on age and conditions of the vehicle, how/where the vehicle is used and other factors"
              />
       </div>
    ]

    if(query.vehicle_model) {
       inputs = inputs.filter(input => input.key !== 'vehicleModelFilter')

       if(query.vehicle_model === 'bZ4X') {
              inputs = inputs.filter(input => input.key !== 'milesDriven')
       }
    }

    const renderInputs = (inputs) => {
        return inputs.map(input => {
            return (
                <Grid key={input.type.name} item xs={12}>
                    {input}
                </Grid>
            )
        })
    }

    const carbonSavings = gasEmission - evEmission
    const carbonPercentage = FormatAsPercent(carbonSavings / gasEmission * 100)

    const left = (
       <div className='filters-container'>
              <h2>Filters</h2>
              {renderInputs(inputs)}

       </div>
    );

    const middle = (
       <div className="emissions-container">
              <h2>Reduce your emissions from driving by up to <strong>{carbonPercentage}</strong>.</h2>
              <h3>Over 5 years, that could keep up to <strong>{FormatAsThousands(carbonSavings)} lbs. of CO{String.fromCodePoint(8322)}</strong> out of our atmosphere.</h3>
              <BarGraph
                     graphData={graphData}
                     labels={labels}
                     backgroundColor={backgroundColor}
              />
       </div>
    );

    return (
        <TabStructure
            left={left}
            middle={middle}
        />
    )
}

export default Emissions
