import React, {useState} from 'react';

import { FormattedMessage } from 'react-intl';
import { useWidget } from '../../../context/widgetContext';

const InputElectricityRate = () => {
  const { userPrefs } = useWidget()
  const [inputValue, setInputValue] = useState(Math.round(userPrefs.get('electricityRate')) / 100);

  const handleChange = (e) => {
       const newValue = e.target.value;
       // Ensure newValue is a valid number
       if (!isNaN(newValue)) {
         // Update local state
         setInputValue(newValue);
         // Update userPrefs
         userPrefs.set({ electricityRate: newValue * 100 });
       }
   };

  return (
    <>
       <div className='form-group currency-wrap'>
              <div className='currency-input'>
                     <input
                            value={inputValue}
                            className="text-currency form-control form-control-input"
                            type="number"
                            placeholder="0.0001"
                            step={0.0001}
                            min={0}
                            max={100}
                            maxLength={5}
                            onChange={handleChange}
                     />
              </div>
              <div className='currency-disclaimer'>
                     <p>
                            <FormattedMessage
                            id="ev.electricityRateCalc"
                            defaultMessage="Electricity rate is calculated as the average for six month of summer off-peak and six months off-peak for Rate A, the cheapest rate available for your zip code."
                            description="Electricicty Rate Text"
                            />
                     </p>
              </div>
      </div>
    </>
  );
};

export default InputElectricityRate;
