import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";
import unique from "lodash/uniq";
import { useDispatch, useSelector } from 'react-redux';
import { getActiveVehicle } from "../../../../redux/selector";
import { updateActiveVehicle, updateEquivalentGasMPG, updateVehicles  } from "../../../../redux/Slices/userSlice";

const SelectVehicleModelFilter = ({
  id = "select-vehicle-model-filter",
  vehicles,
  makeOption,
  ...rest
}) => {
  const intl = useIntl();

  const label = intl.formatMessage
    ? intl.formatMessage({ id: "model", defaultMessage: "Model" })
    : "Model";

  const dispatch = useDispatch()
  const vehicle = useSelector(getActiveVehicle)


  const vehicleModelOptions = (vehicles) => {
    vehicles = vehicles.filter((vehicle) => {
      return vehicle.make === "Toyota";
    });

    vehicles.sort((a, b) => {
      return a.model.localeCompare(b.model);
    });

    return unique((vehicles || []).map((ev) => ev.model));
  };

  const updateVehicle = (vehicle) => {
    dispatch(updateActiveVehicle(vehicle))
    dispatch(updateEquivalentGasMPG(30))
  }

  useEffect(() => {
    if(vehicle) {
      const sortedVehiclesByMake = vehicles.filter(v => v.make === makeOption);
      const sortedVehicles = sortedVehiclesByMake.filter(v => v.model === vehicle);
 
      dispatch(updateVehicles(sortedVehicles[0]?.handle))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vehicle, vehicles])

  return (
    <Select
      id={id}
      value={vehicle}
      label={label}
      optionNames={[
        intl.formatMessage
          ? intl.formatMessage({
              id: "incentives.selectModel",
              defaultMessage: "Select Model",
            })
          : "Select Model",
        ...(vehicleModelOptions(vehicles) || []),
      ]}
      optionValues={["", ...(vehicleModelOptions(vehicles, makeOption) || [])]}
      handler={(e) => updateVehicle(e.target.value)}
    />
  );
};

export default SelectVehicleModelFilter;

SelectVehicleModelFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
