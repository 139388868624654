import React  from "react";
import PropTypes from "prop-types";

import Select from "../../../../components/shared/InputElements/Select";
import { useDispatch, useSelector } from "react-redux";
import { getTaxFilingStatus } from "../../../../redux/selector";
import { updateFilingStatus } from "../../../../redux/Slices/userSlice";
import { useIntl } from "react-intl";

const ChooseTaxFilingStatus = ({ id = "tax-filing-status", ...rest }) => {
  const intl = useIntl();

  const dispatch = useDispatch()
  const taxStatus = useSelector(getTaxFilingStatus)

  const updateTax = (provider) => {
    dispatch(updateFilingStatus(provider))
  }

  return (
    <>
      <div>
        <Select
          id={id}
          isSpecial={true}
          value={taxStatus}
          ariaLabel="Tax Filing Status"
          optionNames={[
            intl.formatMessage
              ? intl.formatMessage({
                  id: "incentives.single",
                  defaultMessage: "Single",
                })
              : "Single",
            intl.formatMessage
              ? intl.formatMessage({
                  id: "incentives.married",
                  defaultMessage: "Married",
                })
              : "Married",
            intl.formatMessage
              ? intl.formatMessage({
                  id: "incentives.headOfHousehold",
                  defaultMessage: "Head of Household",
                })
              : "Head of Household",
          ]}
          optionValues={["single", "joint", "head_of_household"]}
          handler={(e) => updateTax(e.target.value)}
          {...rest}
        />
      </div>
    </>
  );
};

export default ChooseTaxFilingStatus;

ChooseTaxFilingStatus.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
