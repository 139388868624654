import React from 'react';

const TabStructure = ({ left, middle, right }) => {
    return (
       <section className="container-fluid" >
              <div className="container">
                     {left}
                     {middle}
                     {right}
              </div>
       </section>
    )
}

export default TabStructure
