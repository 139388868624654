import React from "react";
import PropTypes from "prop-types";

import { FormatAsCents } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { useWidget } from "../../../context/widgetContext";

const descriptionFn = val => FormatAsCents(val / 100) + " / gallon";

const SlideGasolinePrice = ({
  id = "gasoline-price-range",
  description = descriptionFn,
  ...rest
}) => {
  const { userPrefs } = useWidget()
  return (
    <Range
      id={id}
      value={userPrefs.get("gasolinePriceInCentsPerGal")}
      rangeMin={200}
      rangeMax={1000}
      rangeStep={10}
      description={description}
      handler={e =>
        userPrefs.set({ gasolinePriceInCentsPerGal: e.target.value })
      }
      {...rest}
    />
  );
};

export default SlideGasolinePrice;

SlideGasolinePrice.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
  tooltip: PropTypes.string
};
