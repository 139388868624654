import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Uuid from '../../../utils/Uuid/Uuid';
import electricIcon from '../../assets/images/icons/Electric.svg';
import gasolineIncon from '../../assets/images/icons/gasoline.svg';
import phevIcon from '../../assets/images/icons/PHEV.svg';
import SelectArrow from '../../assets/images/icons/select-arrow.svg';
import Select, { components } from 'react-select';

import { useIntl } from 'react-intl';

const vehicleMakeOptions = (vehicles) => {
  let vehicleMakes = new Set();
  vehicles.forEach((vehicle) => {
    if (vehicle) {
      vehicleMakes.add(vehicle.make);
    }
  });

  vehicleMakes = [...vehicleMakes];

  vehicleMakes.sort((a, b) => {
    return a.localeCompare(b);
  });

  return vehicleMakes.map((make) => {
    return (
      <option value={make} key={make}>
        {make}
      </option>
    );
  });
};

const vehicleModelOptions = (vehicles, makeOption) => {
  if (makeOption) {
    vehicles = vehicles.filter((vehicle) => {
      return vehicle.make === makeOption;
    });

    vehicles.sort((a, b) => {
      return a.model.localeCompare(b.model);
    });

    return vehicles.map((vehicle) => {
      const modelAndTrim = vehicle.trim
        ? `${vehicle.model} ${vehicle.trim}`
        : vehicle.model;
      return (
        <option value={modelAndTrim} key={Uuid()} data-handle={vehicle.handle}>
          {modelAndTrim}
        </option>
      );
    });
  }
};

const ComparedVehicle = ({
  electricVehicles,
  gasolineVehicles,
  setVehicle,
}) => {
  const allVehicles = electricVehicles.concat(gasolineVehicles);

  const [evOptions, setEvOptions] = useState(allVehicles);
  const [fuelOption, setFuelOption] = useState('');
  const [makeOption, setMakeOption] = useState('');
  const [modelOption, setModelOption] = useState('');

  const intl = useIntl();

  if (allVehicles.length === 0) return null;

  const onChangeFuelType = (e) => {
    const newFuelType = e.value;
    let filteredFuelVehicles;
    if (newFuelType === 'PHEV' || newFuelType === 'BEV') {
      filteredFuelVehicles = electricVehicles.filter((vehicle) => {
        return vehicle.fuel === newFuelType;
      });
    } else if (newFuelType === 'gas') {
      filteredFuelVehicles = gasolineVehicles.filter((vehicle) => {
        return vehicle.fuel === newFuelType;
      });
    } else {
      filteredFuelVehicles = allVehicles;
    }

    setFuelOption(newFuelType);
    setMakeOption('');
    setModelOption('');
    setEvOptions(filteredFuelVehicles);
  };

  const onChangeMake = (e) => {
    const newMakeType = e.target.value;
    setMakeOption(newMakeType);
  };

  const onChangeModelType = (e) => {
    const newModelType = e.target.value;
    const vehicleHandle =
      e.target.options[e.target.selectedIndex].dataset.handle;

    if (newModelType === 'Select a Model') {
      setModelOption(newModelType);
      return;
    }

    const selectedVehicle = evOptions.find((vehicle) => {
      return vehicle.handle === vehicleHandle;
    });

    setFuelOption(selectedVehicle.fuel);
    setModelOption(newModelType);
    setVehicle(selectedVehicle);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={SelectArrow} alt="" height="20px" width="20px" style={{marginRight:"10px"}} />
      </components.DropdownIndicator>
    );
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 59,
      minHeight: 40,
      fontSize: '14px',
      color: '#666666',
    }),
  };
  const options = [
    {
      value: 'PHEV',
      label: (
        <div>
          <img src={phevIcon} alt="" />{' '}
          {intl.formatMessage({
            id: 'vehicle.vehicle.plugInHybrid',
            defaultMessage: 'Plugin Hybrid',
          })}
        </div>
      ),
    },
    {
      value: 'BEV',
      label: (
        <div>
          <img src={electricIcon} alt="" />{' '}
          {intl.formatMessage({
            id: 'vehicle.allElectric',
            defaultMessage: 'BEV',
          })}
        </div>
      ),
    },
    {
      value: 'gas',
      label: (
        <div>
          <img src={gasolineIncon} alt="" />{' '}
          {intl.formatMessage({
            id: 'vehicle.gas',
            defaultMessage: 'gas',
          })}
        </div>
      ),
    },
  ];

  return (
       <div className="ComparedVehicle">
              <div className="row" style={{ justifyContent: 'center' }}>
                     <div className="col-sm-12">
                            <div className="input-group-container">
                                   <label>{intl.formatMessage({
                                          id: 'compareVehicles.selectFuelTitle',
                                          defaultMessage: 'Fuel Type',
                                   })}</label>
                                   <div className="input-group">
                                          <Select
                                                 defaultValue={fuelOption}
                                                 onChange={onChangeFuelType}
                                                 options={options}
                                                 components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                                                 aria-label='Select Fuel Type'
                                                 placeholder={intl.formatMessage({
                                                        id: 'compareVehicles.selectFuel',
                                                        defaultMessage: 'Fuel Type',
                                                 })}
                                                 styles={customStyles}
                                          />
                                   </div>
                            </div>
                            <div className="input-group-container">
                                   <label>{intl.formatMessage({
                                          id: 'compareVehicles.selectMakeTitle',
                                          defaultMessage: 'Vehicle Make',
                                   })}</label>
                                   <div className="input-group">
                                          <select
                                                 className="form-control"
                                                 value={makeOption}
                                                 onChange={onChangeMake}
                                                 style={{ color: `#666666` }}
                                                 aria-label='Select Make'
                                          >
                                                 <option defaultValue="">
                                                 {intl.formatMessage({
                                                        id: 'compareVehicles.selectMake',
                                                        defaultMessage: 'Select Make',
                                                 })}
                                                 </option>
                                                 {vehicleMakeOptions(evOptions)}
                                          </select>
                                   </div>
                            </div>
                            <div className='input-group-container'>
                                   <label>{intl.formatMessage({
                                          id: 'compareVehicles.selectModelTitle',
                                          defaultMessage: 'Vehicle Model',
                                   })}</label>
                                   <div className="input-group">
                                          <select
                                                 className="form-control"
                                                 value={modelOption}
                                                 onChange={onChangeModelType}
                                                 aria-label='Select Model'
                                                 style={{ color: `#666666` }}
                                          >
                                                 <option defaultValue="">
                                                 {intl.formatMessage({
                                                        id: 'compareVehicles.selectModel',
                                                        defaultMessage: 'Select a Model',
                                                 })}
                                                 </option>
                                                 {vehicleModelOptions(evOptions, makeOption)}
                                          </select>
                                   </div>
                            </div>
                     </div>
              </div>
       </div>
  );
};

ComparedVehicle.propTypes = {
  vehicle: PropTypes.object,
  electricVehicles: PropTypes.array,
  setVehicle: PropTypes.func,
};

ComparedVehicle.defaultProps = {
  vehicle: {},
  electricVehicles: [],
};

export default ComparedVehicle;
