import React, { useEffect } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import SlideHouseholdIncome from '../../../components/InputComponents/SlideHouseholdIncome/SlideHouseholdIncome';
import SelectHouseholdSize from '../InputComponents/SelectHouseholdSize/SelectHouseholdSize';
import ChooseTaxFilingStatus from '../InputComponents/ChooseTaxFilingStatus/ChooseTaxFilingStatus.js';
import ChooseCanTurnInClunker from '../InputComponents/ChooseCanTurnInClunker/ChooseCanTurnInClunker';
import SelectElectricityProvider from '../../../components/InputComponents/SelectElectricityProvider/SelectElectricityProvider.js';
import SelectVehicleModelFilter from '../InputComponents/SelectVehicleModelFilter/SelectVehicleModelFilter';
import SelectVehicleTrimFilter from '../InputComponents/SelectVehicleTrimFilter/SelectVehicleTrimFilter';

import ToolTip from '../../../components/shared/ToolTip/ToolTip';

import { FormattedMessage } from 'react-intl';

import './IncentiveScoreOptions.scss';
import { useWidget } from '../../../context/widgetContext.js';
import TextFieldZipcode from '../../../components/TextField/TextFieldZipcode.js';

const IncentiveScoreOptions = ({ userLocation, electricVehicles }) => {
  const { userPrefs } = useWidget();
  const query = queryString.parse(window.location.search);

  const RenderItem = ({ component, tooltip_id, title_id, message }) => (
    <div className="input-group-container">
      <div className="incentive-label-select">
        <FormattedMessage
          id={title_id}
          defaultMessage={'Item Title'}
          description={'Item Title'}
        />
        {tooltip_id ? <ToolTip message={message} id={tooltip_id} /> : null}
      </div>
      {component}
    </div>
  );

  useEffect(() => {
    userPrefs.set({ workingZipcode: query.zipcode  });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div className="input-well MatchScoreOptions">
      <h2>
        <FormattedMessage
          id="evs.incentivesFilterHeader"
          defaultMessage="Filters"
          description="Filters"
        />
      </h2>
      <TextFieldZipcode />

      {!query.vehicle_model &&
        <SelectVehicleModelFilter
          vehicles={electricVehicles}
          makeOption={userPrefs.get('vehicleMakeFilter')}
        />
      }
      <SelectVehicleTrimFilter
        vehicles={electricVehicles}
        makeOption={userPrefs.get('vehicleMakeFilter')}
        modelOption={userPrefs.get('vehicleModelFilter')}
      />
      <hr/>
      <RenderItem
        component={<SelectElectricityProvider location={userLocation} />}
        tooltip_id="electricity_provider_tooltip"
        title_id={"incentives.electricityProvider"}
        message="Determines eligibility for incentives specific to your electricity provider."
      />
      <RenderItem
        component={<SlideHouseholdIncome />}
        tooltip_id="household_income_tooltip"
        title_id={'incentives.householdIncome'}
        message="Combine income from all household members. Used to determine incentive eligibility."
      />
      <RenderItem
        component={<SelectHouseholdSize />}
        tooltip_id="household_size_tooltip"
        title_id={'incentives.householdSize'}
        message="Include all members of your household (spouse, dependants, etc). Used to determine incentive eligibility."
      />
      <RenderItem
        component={<ChooseTaxFilingStatus />}
        tooltip_id="filing_status_tooltip"
        title_id={'incentives.taxFilingStatus'}
        message="Used to determine incentive eligibility."
      />
      <RenderItem
        component={<ChooseCanTurnInClunker />}
        tooltip_id="trade_in_tooltip"
        title_id={'incentives.tradeIn'}
        message="Retiring an old gas vehicle may be required for certain incentives."
      />
 
      <div className="incentives-disclaimer">
        <FormattedMessage
          id="incentives.disclaimer"
          defaultMessage="Government incentives may vary depending on customer’s actual qualifications, are subject to change without notice, and do not constitute tax/legal advice. Consult with a tax professional to determine your eligibility. All incentives are subject to approval by issuing entity."
          description="Incentive Discalimer"
        />
      </div>
    </div>
  );
};

export default IncentiveScoreOptions;

IncentiveScoreOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
