const ElectricityCost = {
  perHundredElectricMiles: function (
    vehicle,
    electricityRate,
    costPublicCharging,
    publicChargingPercentage
  ) {
    let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : '';

    if (!['BEV', 'PHEV'].includes(fuelType.toUpperCase())) return 0;

    const publicChargingPercent = publicChargingPercentage / 100;

    const electricityCostInDollarsPerKwh =
      publicChargingPercent > 0
        ? (electricityRate / 100) * (1 - publicChargingPercent) +
          Number(costPublicCharging) * publicChargingPercent
        : electricityRate / 100;

    const vehicleKwhPerMile =
      vehicle.electric_efficiency && parseFloat(vehicle.electric_efficiency) > 0
        ? vehicle.electric_efficiency
        : 0;

    let cost = electricityCostInDollarsPerKwh * vehicleKwhPerMile;

    return isFinite(cost) ? cost : 0;
  },

  perMile: function (
    vehicle,
    electricityRate,
    electricMilesPortionForPhev,
    costPublicCharging,
    publicChargingPercentage
  ) {
    let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : '';

    if (!['BEV', 'PHEV'].includes(fuelType.toUpperCase())) return 0;

    const PHEVFuelSplitAdjustment =
      ['PHEV'].includes(fuelType.toUpperCase()) &&
      parseFloat(electricMilesPortionForPhev) >= 0
        ? electricMilesPortionForPhev / 100
        : 1;

    let cost =
      (this.perHundredElectricMiles(
        vehicle,
        electricityRate,
        costPublicCharging,
        publicChargingPercentage
      ) *
        PHEVFuelSplitAdjustment) /
      100;

    return isFinite(cost) ? cost : 0;
  },
};

export default ElectricityCost;
