import React from "react";
import PropTypes from "prop-types";


import Select from "../../../../components/shared/InputElements/Select";
import { useDispatch, useSelector } from "react-redux";
import { getHouseholdSize } from "../../../../redux/selector";
import { updateSize } from "../../../../redux/Slices/userSlice";


const SelectHouseholdSize = ({ id = "household-size", ...rest }) => {
  const dispatch = useDispatch()
  const size = useSelector(getHouseholdSize)

  const updateHouseSize = (size) => {
    dispatch(updateSize(size))
  }

  return (
    <Select
      id={id}
      isSpecial={true}
      ariaLabel="Household Size"
      value={size}
      optionNames={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
      optionValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
      handler={(e) => updateHouseSize(e.target.value)}
    />
  );
};

export default SelectHouseholdSize;

SelectHouseholdSize.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};
