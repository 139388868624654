import React, { useState } from 'react';

const TextField = ( {title, defaultValue, onChange, maxLength, tooltip, tooltipPlacement, className, id} ) => {
       const [inputValue, setInputValue] = useState(defaultValue);

       const handleInputChange = (e) => {
              setInputValue(e.target.value);
       };

       const handleKeyDown = (e) => {
              if (e.key === 'Enter') {
                     onChange(inputValue);
              }
       };

       const handleButtonClick = () => {
              onChange(inputValue);
       };

    return (
       <>
              <input
                     id={id}
                     className={"form-control"}
                     value={inputValue}
                     onBlur={(e) => onChange(e.target.value)}
                     onKeyDown={handleKeyDown}
                     maxLength={maxLength}
                     onChange={handleInputChange}
              />
              <button
                     type="button"
                     className="btn btn-default mb-2"
                     onClick={handleButtonClick}
                     id="update-zipcode-button">
                     Update
              </button>
       </>
    )
}

export default TextField
