import React, {useState } from 'react';
import PropTypes from 'prop-types';

import ControlledInputZipcode from '../InputComponents/InputZipcode/ControlledInputZipcode';
import SelectVehicleModelFilter from '../../client_customizations/components/InputComponents/SelectVehicleModelFilter/SelectVehicleModelFilter';
import SelectVehicleTrimFilter from '../../client_customizations/components/InputComponents/SelectVehicleTrimFilter/SelectVehicleTrimFilter';
import SlideElectricMilesPortionForPhev from '../InputComponents/SlideElectricMilesPortionForPhev/SlideElectricMilesPortionForPhev';
import SlideMilesDrivenAnnually from '../InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually';
import SlideMonthsOfOwnership from '../InputComponents/SlideMonthsOfOwnership/SlideMonthsOfOwnership';
import SelectPurchaseMethod from '../InputComponents/SelectPurchaseMethod/SelectPurchaseMethod';
import SlideGasolinePrice from '../InputComponents/SlideGasolinePrice/SlideGasolinePrice';
import isPHEV from '../../functions/vehicle/isPHEV';
import { FormattedMessage, useIntl } from 'react-intl';
import InputElectricityRate from '../InputComponents/InputElectricityRate/InputElectricityRate';
import { useWidget } from '../../context/widgetContext.js';
import { ValidateFiveDigitCode } from '../../utils/Helpers/USPostalCodeValidator';
import ToolTip from '../shared/ToolTip/ToolTip';

const CostOfOwnershipOptions = ({ cars }) => {
       const { userPrefs, electricVehicles, userLocation } = useWidget();
       const intl = useIntl();
       const [workingZipcode, setWorkingZipcode] = useState(
              userPrefs.get('zipcode')
       );
       if (!cars || cars.length === 0) return null;
       const phevExists = cars.some((car) => isPHEV(car));

       const handleFormSubmit = () => {
              if (ValidateFiveDigitCode(workingZipcode)) {
                     userPrefs.syncWorkingZipcode();
                     userPrefs.toggleIncentivePrefsModal();
              }
       };

       let renderElectricMilesPortionForPhev = phevExists ? (
              <SlideElectricMilesPortionForPhev />
       ) : null;

       const RenderItem = ({ component, tooltip_id, title_id, message }) => (
              <div className="input-group-container">
                <div className="incentive-label-select">
                  <FormattedMessage
                    id={title_id}
                    defaultMessage={'Item Title'}
                    description={'Item Title'}
                  />
                  {tooltip_id ? <ToolTip message={message} id={tooltip_id} /> : null}
                </div>
                {component}
              </div>
       );

return (
       <>
              <div className="input-well">
                     <h2><FormattedMessage
                     id="evs.incentivesFilterHeader"
                     defaultMessage="Filters"
                     description="Filters"
                     /></h2>
                     <div className='input-group-container'>
                            <ControlledInputZipcode
                                   zipcode={workingZipcode}
                                   handleSubmit={(e) => {
                                   handleFormSubmit();
                                   }}
                                   workingZipcode={workingZipcode}
                                   setZipcode={(e) => {
                                   setWorkingZipcode(e.target.value);
                                   if (ValidateFiveDigitCode(e.target.value)) {
                                          userPrefs.set({ workingZipcode: e.target.value });
                                   }
                                   }}
                                   id="input-zipcode-for-rate-comp-launch"
                                   isUpdating={userPrefs.zipcodeIsUpdating}
                                   isNotFound={userPrefs.zipcodeIsNotFound}
                                   showButtonBelow
                                   hideValidateRequest
                                   tooltip={intl.formatMessage({
                                   id: 'zipcodeTooltip',
                                   defaultMessage:
                                          'Zip code is used to display available incentives',
                                   })}
                                   userLocation={userLocation}
                            />
                     </div>
                     <hr/>
                     <SelectVehicleModelFilter
                            vehicles={electricVehicles}
                            makeOption={userPrefs.get('vehicleMakeFilter')}
                     />
                     <SelectVehicleTrimFilter
                            vehicles={electricVehicles}
                            makeOption={userPrefs.get('vehicleMakeFilter')}
                            modelOption={userPrefs.get('vehicleModelFilter')}
                     />
                     <hr/>
                     <RenderItem
                            title_id={"ev.milesDrivenAnnually"}
                            component={<SlideMilesDrivenAnnually />}
                            tooltip_id="electric-miles-annual-tooltip"
                            message="Estimate the number of miles you drive each year."
                     />
                     <RenderItem
                            title_id={"ev.electricPortion"}
                            component= {renderElectricMilesPortionForPhev}
                            tooltip_id="electric-electric-miles-annual-tooltip"
                            message="A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity."
                     />
                     <RenderItem
                            title_id={"ev.electricPortion"}
                            component= {<SlideMonthsOfOwnership />}
                            tooltip_id="electric-phev-balance-tooltip"
                            message="A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity."
                     />
                     <RenderItem
                            title_id={"gasolinePriceLabel"}
                            component= {<SlideGasolinePrice />}
                            tooltip_id="gasoline-price-tooltip"
                            message="A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity."
                     />
                     <RenderItem
                            title_id={"ev.purchaseMethod"}
                            component= {<SelectPurchaseMethod />}
                            tooltip_id="purchase-method-tooltip"
                            message="In a lease, you have to return the car after the lease period. In the case of loan financing, you keep the car after the loan is paid off."
                     />
                     <RenderItem
                            title_id={"comparison.MPG"}
                            component= {renderElectricMilesPortionForPhev}
                            tooltip_id="electric-miles-forphev-tooltip"
                            message="A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity."
                     />
                     <RenderItem
                            title_id={"home.electricity.rate"}
                            component= {<InputElectricityRate />}
                            tooltip_id="electric-rate-tooltip"
                            message="A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity."
                     />
       </div>
    </>
  );
};

CostOfOwnershipOptions.propTypes = {
  car: PropTypes.object,
};
export default CostOfOwnershipOptions;
