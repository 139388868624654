import React from "react";
import PropTypes from "prop-types";


import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getTradeInVehicle } from "../../../../redux/selector";
import { updateTradeIn } from "../../../../redux/Slices/userSlice";


const ChooseCanTurnInClunker = ({
  id = "can-turn-in-clunker",
  incentives,
  ...rest
}) => {
  const intl = useIntl();

  const dispatch = useDispatch()
  const tradeIn = useSelector(getTradeInVehicle)

  const updateTrade = (tradeIn) => {
    dispatch(updateTradeIn(tradeIn))
  }
  
  return (
    <Select
      id={id}
      isSpecial={true}
      ariaLabel="Can Turn In Clunker"
      value={tradeIn}
      optionNames={[
        intl.formatMessage ? intl.formatMessage({ id: "yes", defaultMessage: "Yes" }): "Yes",
        intl.formatMessage ? intl.formatMessage({ id: "no", defaultMessage: "No" }): "No",
      ]}
      optionValues={["true", "false"]}
      handler={(e) => updateTrade(e.target.value)}
      {...rest}
    />
  );
};

export default ChooseCanTurnInClunker;

ChooseCanTurnInClunker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  incentives: PropTypes.array,
};
