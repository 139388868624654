import { configureStore } from '@reduxjs/toolkit'
import { unifiedApi } from "./services/unifiedAPIQuery"
import userSlice from './Slices/userSlice'


export const store = configureStore({
  reducer: {
    user: userSlice,
    [unifiedApi.reducerPath]: unifiedApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(unifiedApi.middleware)
})
