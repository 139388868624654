import React  from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { FormatAsDollars } from "../../../utils/Helpers/Format";
import "./DoughnutGraph.scss";

const DoughnutGraph = ({
  header,
  subHeader,
  graphData,
  labels = ["Incentives", "Fuel savings", "Maintenance", "Insurance"],
  backgroundColor = ["#890713", "#E10A1D", "#D8D8D8", "#767676"],
  vehicle,
  activeTrim,
}) => {
  const data = {
    maintainAspectRatio: true,
    responsive: true,
    labels: labels,
    datasets: [
      {
        data: graphData,
        backgroundColor: backgroundColor,
      },
    ],
  };

  const options = {
       responsive: true,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    layout: {
       padding: 50, // Remove padding
     },
    plugins: {
       legend: {
               display: false,
            },
      tooltip: {
        callbacks: {
          label({ label, raw }) {
            return `${label} ${FormatAsDollars(raw)}`;
          },
        },
      },
    },
  };


  return (
       <div className="doughnutGraph-container">
              <div className="doughnutGraph">
                     <Doughnut data={data} options={options} />
              </div>
              <div className="doughnutGraph-legend-container">
                     <div className="doughnutGraph-legend">
                            <span className="indication-block" style={{backgroundColor: backgroundColor[0]}}></span>
                            <span>{labels[0]}</span>
                     </div>
                     <div className="doughnutGraph-legend">
                            <span className="indication-block" style={{backgroundColor: backgroundColor[1]}}></span>
                            <span>{labels[1]}</span>
                     </div>
                     <div className="doughnutGraph-legend">
                            <span className="indication-block" style={{backgroundColor: backgroundColor[2]}}></span>
                            <span>{labels[2]}</span>
                     </div>
                     <div className="doughnutGraph-legend">
                            <span className="indication-block" style={{backgroundColor: backgroundColor[3]}}></span>
                            <span>{labels[3]}</span>
                     </div>
              </div>
       </div>
  );
};

export default DoughnutGraph;

DoughnutGraph.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  graphData: PropTypes.array,
  labels: PropTypes.array,
  backgroundColors: PropTypes.array,
};
