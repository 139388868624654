import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ComparedVehicle from './ComparedVehicle';
import CostOfOwnership from '../CostOfOwnership/CostOfOwnership';
import CostOfOwnershipOptions from '../../../components/CostOfOwnershipOptions/CostOfOwnershipOptions';
import './CompareVehicles.scss';
import ComparedCarDetails from './ComparedCarDetails';
import ComparedVehicleToggle from './ComparedVehicleToggle';
import { FormattedMessage, useIntl } from 'react-intl';

const CompareVehicles = ({
  electricVehicles,
  gasolineVehicles,
}) => {
       const intl = useIntl();
  const [comparedVehicle1, setComparedVehicle1] = useState(null);
  const [comparedVehicle2, setComparedVehicle2] = useState(null);
  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState(0);

  const cars = [comparedVehicle1, comparedVehicle2].filter(
    (car) => car !== null
  );
  const car = cars[0];
  const equivalentGasVehicle = cars[1];

  if (!electricVehicles || !gasolineVehicles) {
    return null;
  }

  gasolineVehicles = gasolineVehicles.filter((vehicle) => {
    return vehicle.images.length !== 0;
  });

  const title = {
    costAnalysis: intl.formatMessage({
      id: 'graph.title.costAnalysis',
      defaultMessage: 'Cost Analysis',
    }),
    speedOfCharge: intl.formatMessage({
      id: 'graph.title.speedOfCharge',
      defaultMessage: 'Speed of Charge',
    }),
    electricRange: intl.formatMessage({
      id: 'graph.title.electricRange',
      defaultMessage: 'Electric Range',
    }),
  };

  let vehicle1ContainerClasses = ['compared-vehicle'];
  if (selectedVehicleNumber !== 0) vehicle1ContainerClasses.push('d-none');

  let vehicle2ContainerClasses = ['compared-vehicle'];
  if (selectedVehicleNumber !== 1) vehicle2ContainerClasses.push('d-none');

  return (
    <section className="container-fluid" id="compare-vehicles">
      <div className="container">
              <div className="compare-vehicles-toggle">
                     <ComparedVehicleToggle
                            selectedVehicleNumber={selectedVehicleNumber}
                            onChangeVehicleNumber={(number) =>
                                   setSelectedVehicleNumber(number)
                            }
                            comparedVehicles={[
                            comparedVehicle1,
                            comparedVehicle2,
                            ]}
                     />
              </div>
              <div className="compare-vehicles-title">
                     <h2>
                            <FormattedMessage
                            id="compareVehicles.v2"
                            defaultMessage="Compare Vehicles"
                            description="Compare Vehicles Title"
                            />
                     </h2>
                     <p className="lead">
                            <FormattedMessage
                            id="compareVehicles.subTitle"
                            defaultMessage="See All Vehicles &gt;"
                            description="Compare Vehicles Sub Title"
                            />
                     </p>
              </div>

              <section className="compared-section">
                     <div className={vehicle1ContainerClasses.join(' ')}>
                            <ComparedVehicle
                                   vehicle={comparedVehicle1}
                                   setVehicle={setComparedVehicle1}
                                   electricVehicles={electricVehicles}
                                   gasolineVehicles={gasolineVehicles}
                            />
                            {comparedVehicle1 && (
                            <ComparedCarDetails vehicle={comparedVehicle1} />
                            )}
                     </div>

                     <div className={vehicle2ContainerClasses.join(' ')}>
                            <ComparedVehicle
                                   vehicle={comparedVehicle2}
                                   setVehicle={setComparedVehicle2}
                                   electricVehicles={electricVehicles}
                                   gasolineVehicles={gasolineVehicles}
                            />
                            {comparedVehicle2 && (
                            <ComparedCarDetails vehicle={comparedVehicle2} />
                            )}
                     </div>
              </section>
       </div>

        {cars.length > 1 ? (
          <section className="compared-graph-section">
              <div className='container'>
                     <div className="filters-container">
                            <CostOfOwnershipOptions
                                   cars={cars}
                                   usedEv={null}
                                   comparison={'comparison'}
                                   car={car}
                                   equivalentGasVehicle={equivalentGasVehicle}
                            />
                     </div>
                     <div className="graphs-container">
                            <CostOfOwnership
                                   cars={cars}
                                   title={title.costAnalysis}
                                   comparison={'comparison'}
                            />
                     </div>
              </div>
          </section>
        ) : (
          null
        )}
    </section>
  );
};

export default CompareVehicles;

CompareVehicles.propTypes = {
  electricVehicles: PropTypes.array,
};
