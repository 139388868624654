export const isPHEV = vehicle => {
    const fuelType = vehicle?.fuel;
    if(fuelType) {
        return ["PHEV"].includes(fuelType.toUpperCase());
    }
  };
  
const FuelCost = {
    gasolineCost: function(miles, gasolineEfficiency, fuelPrice) {
        miles = Number(miles)
        gasolineEfficiency = Number(gasolineEfficiency)
        fuelPrice = Number(fuelPrice)

        return gasolineEfficiency === 0 ? 0 : fuelPrice * miles / gasolineEfficiency
    },

    electricityCost: function(miles, electricEfficiency, electricityRate) {
        miles = Number(miles)
        electricEfficiency = Number(electricEfficiency)
        electricityRate = Number(electricityRate)

        return electricityRate * miles * electricEfficiency / 100
    },

    hybridAdjustment: function(vehicle, percentElectric) {
        return isPHEV(vehicle) ? percentElectric : 1
    },

    totalCost: function(miles, gasolineEfficiency, ev, electricityRate, fuelPrice, percentElectric) {
        miles = Number(miles)
        gasolineEfficiency = Number(gasolineEfficiency)
        electricityRate = Number(electricityRate)
        fuelPrice = Number(fuelPrice)
        percentElectric = Number(percentElectric) / 100

        const gasReplacementVehicleFuelCost = this.gasolineCost(miles, gasolineEfficiency, fuelPrice)

        const fuelAdjustment = this.hybridAdjustment(ev, percentElectric)

        const evFuelCost = (fuelAdjustment * this.electricityCost(miles, ev?.electric_efficiency, electricityRate) + (1 - fuelAdjustment) * this.gasolineCost(miles, ev?.fossil_fuel_efficiency, fuelPrice))

        const savings = gasReplacementVehicleFuelCost - evFuelCost

        return savings > 0 ? savings : 0
    }
};
export default FuelCost;
