import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { FormatAsDollars } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { getHouseholdIncome } from "../../../redux/selector";
import { updateIncome } from "../../../redux/Slices/userSlice";


const descriptionFn = val => {
  return val === 350000 ? "$350,000+" : FormatAsDollars(val);
};
const SlideHouseholdIncome = ({
  id = "household-income-slider",
  label = "",
  description = descriptionFn,
  ...rest
}) => {

  const dispatch = useDispatch()
  const provider = useSelector(getHouseholdIncome)

  const updateHouseIncome = (income) => {
    dispatch(updateIncome(income))
  }

  return (
    <Range
      id={id}
      value={provider}
      description={description}
      ariaLabel="Household Income"
      label={label}
      rangeMin={5000}
      rangeMax={350000}
      rangeStep={5000}
      handler={e => updateHouseIncome(e.target.value)}
      {...rest}
    />
  );
};

export default SlideHouseholdIncome;

SlideHouseholdIncome.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
