import React from "react";

import Range from "../../../components/shared/InputElements/Range";
import ToolTip from '../../../components/shared/ToolTip/ToolTip';
import { getElectricityPrice } from "../../../redux/selector"
import { useDispatch, useSelector } from 'react-redux';
import { updateElectricityPrice } from "../../../redux/Slices/userSlice";
import { FormatAsTwoDecimals  } from "../../../utils/Helpers/Format";

const descriptionFn = val => {
       return FormatAsTwoDecimals (val);
};

const SlideElectricityPrice = ({
       description = descriptionFn
}) => {
       const dispatch = useDispatch()
       const electricityPrice = useSelector(getElectricityPrice)
       const maxNumber = 1

       const updateEPrice = (price) => {
              if (!isNaN(Number(price))) {
                     if (price > maxNumber) {
                            dispatch(updateElectricityPrice(maxNumber))
                     } else {
                     dispatch(updateElectricityPrice(price))
                     }
              }
       }

       const onBlurUpdateEPrice = (price) => {
              if (!isNaN(Number(price))) {
                     if (price > maxNumber) {
                            dispatch(updateElectricityPrice(maxNumber))
                     } else {
                            dispatch(updateElectricityPrice(Number(price).toFixed(2)))
                     }
              }
       }

    return (
        <div className="input-group-container">
              <div className="incentive-label-select">
                     <label htmlFor="range-electricity-price">Electric Miles Driven</label>
                     <ToolTip message={"Estimate your average price for electricity per kilowatt-hour."} id={"slide-electric-price-tooltip"} />
              </div>
              <Range
                     id="range-electricity-price"
                     value={electricityPrice}
                     description={description}
                     ariaLabel="Annual Mileage"
                     rangeMin={.05}
                     rangeMax={maxNumber}
                     rangeStep={.01}
                     handler={e => updateEPrice(e.target.value)}
                     onBlur={onBlurUpdateEPrice}
              />
       </div>
    )
}

export default SlideElectricityPrice
