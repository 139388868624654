import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getCarCostOfOwnership from '../CostOfOwnership/getCarCostOfOwnership';
import CostToOwnComparisonChart from '../../../components/CostsCharts/CostToOwnComparisonChart/CostToOwnComparisonChart';
import CostToOwnComparisonChartMobile from '../../../components/CostsCharts/CostToOwnComparisonChartMobile';
// import ShowHideButton from "../../../components/ShowHideButton/ShowHideButton";
import { FormatCarName } from '../../../utils/Helpers/Format';
// import Uuid from "../../../utils/Uuid/Uuid"
import { FormattedMessage, useIntl } from 'react-intl';
import { useWidget } from '../../../context/widgetContext';

const CostToOwnComparison = ({
  cars,
  displayEvCostBreakdown,
  displayEvCostBreakdownOption,
  forceUserPrefsPresets,
  hasExtraPadding,
  comparison,
  insuranceData,
  maintenanceData,
}) => {
  const intl = useIntl();
  const {userPrefs} = useWidget();
  const [showCostBreakdown, setShowCostBreakdown] = useState(
    displayEvCostBreakdown
  );

  if (!cars || cars.length === 0) return null;

  cars = cars.filter((car) => {
    return car !== null;
  });

  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;
  const yearsOfOwnership = get('monthsOfOwnership') / 12;

  const carsCosts = cars.map((car) => {
    return getCarCostOfOwnership(
      car,
      userPrefs,
      insuranceData,
      maintenanceData
    );
  });

  const sortedCarCosts = [...carsCosts].sort((carCost1, carCost2) => {
    return carCost1.summed.total - carCost2.summed.total;
  });

  let title;

  if (cars.length === 2) {
    const costDelta = carsCosts[1].summed.total - carsCosts[0].summed.total;

    const costDeltaText =
      costDelta < 0
        ? intl.formatMessage({
            id: 'graph.moreExpensive',
            defaultMessage: 'cost more',
          })
        : intl.formatMessage({
            id: 'graph.cheaper',
            defaultMessage: 'cost less',
          });
    const costDeltaTextEnding =
      intl.formatMessage({
        id: 'graph.toOwnOver',
        defaultMessage: 'to own after ',
      }) +
      yearsOfOwnership +
      intl.formatMessage({ id: 'graph.years', defaultMessage: ' years' });

    title = (
      <>
        <p className="h3 graph-sub-title">
          <FormattedMessage
            id="graph.costOfOwnership.subTitle"
            defaultMessage="The {carName} will {costDeltaText} {costDeltaTextEnding}"
            description="Graph Sub Title"
            values={{
              carName: FormatCarName(cars[0]),
              costDeltaText: costDeltaText,
              costDeltaTextEnding: costDeltaTextEnding,
            }}
          />
        </p>
      </>
    );
  } else {
    title = (
      <>
        <p className="h3 graph-sub-title">
          The {FormatCarName(sortedCarCosts[0].car)} is the least expensive to
          own for {yearsOfOwnership} years
        </p>
      </>
    );
  }

  const carNames = comparison
    ? cars.map((car) => FormatCarName(car))
    : [
        [
          FormatCarName(cars[0]),
          intl.formatMessage({
            id: 'graph.yourEV',
            defaultMessage: '(Your election)',
          }),
        ],
        [
          FormatCarName(cars[1]),
          intl.formatMessage({
            id: 'graph.similarGasVehicle',
            defaultMessage: '(Similar gas vehicle)',
          }),
        ],
      ];

  const selectShowCostBreakdown = displayEvCostBreakdownOption ? (
    <div>
      <span className="mr-4">Why is the cost different?</span>
      <div
        className="form-group form-check text-center"
        style={{ display: 'inline-block' }}
      >
        <input
          type="checkbox"
          className="form-check-input"
          id="exampleCheck1"
          selected={showCostBreakdown}
          onClick={() => setShowCostBreakdown(!showCostBreakdown)}
        />
        <label className="form-check-label" htmlFor="exampleCheck1">
          Show Cost Breakdown
        </label>
      </div>
    </div>
  ) : null;

  return (
       <>
              <div className="chart-container nmb">
                     {title}
                     <div className="d-none d-lg-block d-xl-block">
                            {selectShowCostBreakdown}
                            <div className="desktop-only">
                            <CostToOwnComparisonChart
                                   carNames={carNames}
                                   carsCosts={carsCosts}
                                   displayEvCostBreakdownOption={displayEvCostBreakdownOption}
                                   displayEvCostBreakdown={showCostBreakdown}
                            />
                            </div>
                     </div>
                     <div className="mobile-only">
                            <CostToOwnComparisonChartMobile
                            carNames={carNames}
                            carsCosts={carsCosts}
                            displayEvCostBreakdownOption={displayEvCostBreakdownOption}
                            displayEvCostBreakdown={showCostBreakdown}
                            />
                     </div>
              </div>
              <div className="chart-disclaimer">
                     <p>The information provided herein is for general informational purposes only and should not be construed as professional advice.  Please consult a qualified professional for specific advice tailored to your individual circumstances.</p>
              </div>
       </>
  );
};

export default CostToOwnComparison;

CostToOwnComparison.propTypes = {
  car: PropTypes.object,
  displayFuelOnly: PropTypes.bool,
  displayEvCostBreakdown: PropTypes.bool,
  displayEvCostBreakdownOption: PropTypes.bool,
  forceUserPrefsPresets: PropTypes.bool,
  hasExtraPadding: PropTypes.bool,
};
