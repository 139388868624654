
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import IncentiveCatalog from './../../components/IncentiveCatalog/IncentiveCatalog';
import IncentiveScoreOptions from '../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions';
import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner';
import { FormatAsDollars } from './../../utils/Helpers/Format';
import { useWidget } from "../../context/widgetContext";
import './Incentives.scss';
import { useSelector } from 'react-redux';
import { getProvider } from '../../redux/selector';

const Incentives = () => {
  const { electricVehicles, incentives, userLocation } = useWidget();
  const provider = useSelector(getProvider)

  const filterIncentives = useMemo(() => {
    if (!incentives) return [];
  
    return incentives.filter(incentive => {
      const isAvailable = incentive.available;
      const isFederalAndAvailable = isAvailable && incentive?.grantor === "Federal";
      const isEligible = incentive.evaluation ? incentive?.evaluation.eligibility === 'eligible' : true;
      const isRegionalAndAvailable = isAvailable && incentive?.grantor === userLocation?.region;

      return isAvailable && (
        isFederalAndAvailable || 
        (isEligible && (!provider || incentive.grantor === provider)) ||
        isRegionalAndAvailable
      );
  
    });
  }, [incentives, provider, userLocation]);


  const getIncentiveSum = () => {
      let sum = 0;
      if (!filterIncentives) return FormatAsDollars(sum);

      filterIncentives.forEach((incentive) => {
        if (incentive.evaluation) {
            const amountInPurchase = incentive.evaluation.amount_in_purchase;
            const amountInLease = incentive.evaluation.amount_in_lease;
            if (amountInPurchase && amountInPurchase !== 0) {
                sum += amountInPurchase;
            } else {
                sum += amountInLease;
            }
        } else {
            sum += incentive.max_amount;
        }
      });

      return FormatAsDollars(sum);
  };

  const renderIncentives = filterIncentives ? (
    <IncentiveCatalog incentives={filterIncentives} category="all" />
  ) : (
    <LoadingSpinner />
  );

  const renderOptions = (
    <>
      <IncentiveScoreOptions userLocation={userLocation} electricVehicles={electricVehicles} />
      <br />
    </>
  );

  return (
    <section className="container-fluid" id="incentives">
      <div className='container'>
        <div className="filters-container">
          {renderOptions}
        </div>
        <div className="incentives-container">
              <h2>You could qualify for savings of up to <strong>{getIncentiveSum()}</strong>, inclusive of vehicle tax credits and rebates.</h2>
              {renderIncentives}
        </div>
      </div>
    </section>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
