import React from "react";
import PropTypes from "prop-types";

import { FormatAsPercentRounded } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import { useWidget } from "../../../context/widgetContext";

const descriptionFn = val => FormatAsPercentRounded(val);

const SlideElectricMilesPortionForPhev = ({
  id = "portion-electric-for-phev-range",
  description = descriptionFn,

  ...rest
}) => {
  const { userPrefs } = useWidget()

  return (
    <Range
      id={id}
      value={userPrefs.get("electricMilesPortionForPhev")}
      rangeMin={0}
      rangeMax={100}
      rangeStep={10}
      description={description}
      handler={e =>
        userPrefs.set({ electricMilesPortionForPhev: e.target.value })
      }
      {...rest}
    />
  );
};

export default SlideElectricMilesPortionForPhev;

SlideElectricMilesPortionForPhev.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func,
  tooltip: PropTypes.string
};
