import React from "react";
import PropTypes from "prop-types";


import Select from "../../shared/InputElements/Select";
import { useIntl } from 'react-intl';
import { useWidget } from "../../../context/widgetContext";

const SelectPurchaseMethod = ({
  id = "select-purchase-method",
  ...rest
}) => {
  const intl = useIntl()
  const { userPrefs } = useWidget()

  return (
    <Select
      id={id}
      value={userPrefs.get("purchaseMethod")}
      optionNames={[intl.formatMessage({ id: "ev.cash", defaultMessage: "Cash"}), intl.formatMessage({ id: "ev.loan", defaultMessage: "Loan"}), intl.formatMessage({ id: "ev.lease", defaultMessage: "Lease"})]}
      optionValues={["cash", "loan", "lease"]}
      handler={e => userPrefs.set({ purchaseMethod: e.target.value })}
    />
  );
};

export default SelectPurchaseMethod;

SelectPurchaseMethod.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string
};
