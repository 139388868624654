
import Incentives from '../pages/Incentives/Incentives';
import CostOfOwnership from '../pages/CostOfOwnership/CostOfOwnership';
import CompareVehicles from '../client_customizations/components/CompareVehicles/CompareVehicles';
import Emissions from '../pages/Emissions/Emissions';
import queryString from 'query-string';

const query = queryString.parse(window.location.search);

const paramsArray = query.tab_name?.split(',').map(name => name.trim().toLowerCase()) ?? [];

const moduleConfig = {
    incentivesModule: { 
      id: 'incentives', 
      label: 'Incentives', 
      Component: Incentives, 
      moduleEnabled: paramsArray.includes('incentives')
    },
    cowModule: { 
      id: 'cow', 
      label: 'Cost Of Ownership', 
      Component: CostOfOwnership, 
      moduleEnabled: false
    },
    compareModule: { 
        id: 'compare', 
        label: 'Compare Vehicles', 
        Component: CompareVehicles, 
        moduleEnabled: false
    },
    emissionsModule: { 
        id: 'emissions', 
        label: 'Emissions', 
        Component: Emissions, 
        moduleEnabled: paramsArray.includes('emissions')
    },
};

const sortedModuleConfig = Object.values(moduleConfig).sort((a, b) => {
  const aIndex = paramsArray.indexOf(a.id);
  const bIndex = paramsArray.indexOf(b.id);

  if (aIndex === -1) return 1;
  if (bIndex === -1) return -1;

  return aIndex - bIndex;
});

export default sortedModuleConfig